import { Modal, Spin, message, Checkbox } from 'antd';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../shared/constants';
import { doGetSalesheetFilmsList } from '../../api/films';
import FilmsTable from '../../shared/components/tables/Films';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import { doCreateSalesheet } from '../../api/salesheets';
import { SalesheetCreateParams } from '../../interfaces/salesheet';
import './SalesheetNew.scss';
import CheckCircleIcon from '../../shared/svgs/CheckCircleIcon';
import SalesheetFilterForm from '../../shared/components/forms/salesheet/Filter';
import { getNestedQueryValues, handleDisplayFilterFields, handleFilterParams } from '../../utils/global';
import { CurrentUserI } from '../../interfaces/auth';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { TerritoryI } from '../../interfaces/territory';

const SalesheetNewPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isSelectAllFilms, setIsSelectAllFilms] = useState<boolean>(false);
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const queryParams = useMemo(() => {
    const start = urlSearch.get('start') ?? 1;
    const count = urlSearch.get('count') ?? PAGE_SIZE;
    const titleFilter = urlSearch.get('filter[title]');
    const territoriesFilter = getNestedQueryValues(urlSearch, 'filter[territories]');
    const contractEndDatesFilter = urlSearch.get('filter[contractEndDate]');
    const rightIdsFilter = getNestedQueryValues(urlSearch, 'filter[rightIds]');
    const countryOfOriginFilter = getNestedQueryValues(urlSearch, 'filter[countryOfOrigin]');
    return {
      start,
      count,
      filter: {
        title: titleFilter,
        rightIds: rightIdsFilter,
        territories: territoriesFilter,
        countryOfOrigin: countryOfOriginFilter,
        contractEndDate: contractEndDatesFilter,
      },
    } as any;
  }, [urlSearch]);
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(['films/list', 'list', queryParams], () => doGetSalesheetFilmsList(queryParams));
  const setQueryParams = (params: any) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: any) => {
    setQueryParams({ ...queryParams, ...values });
  };
  const handleFilter = (params: { page: number; per_page: number }) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    let newFilter: any = undefined;
    if (search) {
      newFilter = handleDisplayFilterFields(search);
    }
    const newParams = {
      start: Number(offset),
      count: PAGE_SIZE,
      filter: { ...newFilter },
    };
    setQueryParams(newParams);
  };

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleResetFilter = () => {
    const newParams = { start: 0, count: PAGE_SIZE };
    setQueryParams(newParams);
  };

  const { mutate: createMutate, isLoading: isCreateLoading } = useMutation(doCreateSalesheet, {
    onSuccess: async () => {
      message.info('Create salesheet successfully!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Create salesheet failed! Please try again');
      setSelectedRowKeys([]);
    },
  });

  const handleModalCancel = () => {
    navigate(`/salesheets`, { replace: true });
    setIsModalVisible(false);
  };

  const handleSubmit = async () => {
    const params: SalesheetCreateParams = {
      filmIds: isSelectAllFilms ? ['selectAll'] : selectedRowKeys,
      territories:
        (queryParams?.filter?.territories &&
          queryParams?.filter?.territories.filter((territory: TerritoryI) => territory != null)) ||
        [],
      rightIds: queryParams?.filter?.rightIds || [],
      contractEndDate:
        queryParams?.filter?.contractEndDate == null ? null : Number(queryParams?.filter?.contractEndDate),
      movieTitle: queryParams?.filter?.title || null,
      countryOfOrigin: queryParams?.filter?.countryOfOrigin || [],
    };
    setIsModalVisible(true);
    createMutate(params);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onSelect = (record: any, selected: boolean) => {
    if (selected) {
      setSelectedRowKeys((prevSelectedRowKeys) => {
        const combinedKeys = new Set([...prevSelectedRowKeys, record.id]);
        return Array.from(combinedKeys);
      });
    } else {
      setSelectedRowKeys((prevSelectedRowKeys) => {
        const a = prevSelectedRowKeys.filter((key) => key !== record.id);
        return a;
      });
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelect,
    getCheckboxProps: (record: any) => ({
      disabled: isSelectAllFilms,
      name: record.name,
    }),
  };

  const handleSelectAll = (event: CheckboxChangeEvent) => {
    setIsSelectAllFilms(event.target.checked);
  };

  return (
    <div className="salesheet-new-container">
      <div className="page-container">
        <div className="page-content page-content-filer">
          <div className="page-content-header">
            <h2 className="title-page">Filter</h2>
          </div>
          <SalesheetFilterForm
            handleReset={handleResetFilter}
            queryParams={queryParams}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      <Spin spinning={isCreateLoading}>
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Select Films to Create</h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="page-content-actions">
                <PrimaryButton
                  label="Create Salesheet"
                  onClick={handleSubmit}
                  disabled={!hasSelected && !isSelectAllFilms}
                  loading={isCreateLoading}
                  icon={<CheckCircleIcon />}
                />
                <span style={{ marginLeft: 12 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} ${selectedRowKeys.length > 1 ? 'films' : 'film'}`
                    : isSelectAllFilms
                    ? `Selected ${data?.total} ${data?.total > 1 ? 'films' : 'film'}`
                    : ''}
                </span>
              </div>

              <div>
                <Checkbox onChange={handleSelectAll}>Select All Films</Checkbox>
              </div>
            </div>
            <FilmsTable
              rowKey="id"
              loading={isLoading}
              pagination={pagination}
              dataSource={data?.data}
              onChange2={handleFilter}
              rowSelection={rowSelection}
              currentUser={currentUser}
            />
          </div>
        </div>
      </Spin>
      <Modal
        footer={
          <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 24 }}>
            <PrimaryButton label="Ok" onClick={handleModalCancel} style={{ minWidth: 100 }} />
          </div>
        }
        open={isModalVisible}
        onCancel={handleModalCancel}
        closeIcon={null}
        centered
        width={320}
      >
        <span style={{ fontSize: 16 }}>Email will be send to your email, once sales sheet is ready.</span>
      </Modal>
    </div>
  );
};

export default SalesheetNewPage;
