import { DatePicker, Form, Input, Select, TreeSelect } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { DATE_FORMAT_FRONTEND, getSEACountries } from '../../../constants';
import SecondaryButton from '../../buttons/SecondaryButton';
import ClearAllIcon from '../../../svgs/ClearAllIcon';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from 'react-query';
import { getRightsList } from '../../../../api/rights';
import {
  convertToRightTreeList,
  mergeUniqueStringArray,
  setEndTime,
  getCountriesList,
  getCountryLabel,
  convertDayJSToISOString,
  handleGetCountriesWithSEA,
  getCountryValue,
  replaceWithSEA,
} from '../../../../utils/global';

type Props = {
  handleReset(): void;
  queryParams: any;
  onChange(newParams: any): void;
};

const SalesheetFilterForm: React.FC<Props> = ({ handleReset, queryParams, onChange }) => {
  const [form] = Form.useForm();
  const countries = useMemo(() => getCountriesList(true), []);
  const [treeList, setTreeList] = useState<any[]>([]);

  const { data: rights, isLoading: isRightLoading } = useQuery(['rights', 'list'], () => getRightsList({}), {
    enabled: true,
    staleTime: Infinity,
  });

  const onTitleChange = (event: any) => {
    const { value } = event.target;
    onChange({ filter: { ...queryParams.filter, title: value } });
  };

  const onContractEndChange = (onContractEndChange: Dayjs | null) => {
    let contractEndDate = null;
    contractEndDate = onContractEndChange ? setEndTime(onContractEndChange).valueOf() : null;
    onChange({ filter: { ...queryParams.filter, contractEndDate } });
  };

  const onTerritoryChange = (values: string[]) => {
    let newCountryCodes: string[] = values;
    if (values.includes('SEA')) {
      const sea = getSEACountries;
      newCountryCodes = mergeUniqueStringArray(sea, values);
    }
    const newTerritories = newCountryCodes
      ?.map((item: string) => getCountryLabel(countries, item))
      .filter((value) => value !== 'SEA');
    onChange({ filter: { ...queryParams.filter, territories: newTerritories } });
  };

  const onCountryChange = (values: string[]) => {
    let newCountryCodes: string[] = values;
    if (values.includes('SEA')) {
      const sea = getSEACountries;
      newCountryCodes = mergeUniqueStringArray(sea, values);
    }
    const newCountries = newCountryCodes
      ?.map((item: string) => getCountryLabel(countries, item))
      .filter((value) => value !== 'SEA');
    onChange({ filter: { ...queryParams.filter, countryOfOrigin: newCountries } });
  };

  const onTerritoryRightChange = (value: number[]) => {
    onChange({ filter: { ...queryParams.filter, rightIds: value } });
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
    onChange({ filter: {} });
  };

  useEffect(() => {
    if (queryParams?.filter) {
      let rightIds: number[] | undefined = undefined;
      if (queryParams?.filter?.rightIds) {
        rightIds = queryParams?.filter.rightIds.map((item: number) => Number(item));
      }
      let contractEndDate: Dayjs | undefined = undefined;
      if (queryParams?.filter?.contractEndDate) {
        contractEndDate = convertDayJSToISOString(dayjs(Number(queryParams?.filter.contractEndDate)));
      }
      let countryCodes: string[] | undefined = undefined;
      if (queryParams?.filter?.territories) {
        const newCountries = handleGetCountriesWithSEA(queryParams?.filter?.territories);
        countryCodes = newCountries.map((item: string) => getCountryValue(countries, item) || item);
      }

      let countryOfOrigin: string[] | undefined = undefined;
      if (queryParams?.filter?.countryOfOrigin) {
        const newCountries = handleGetCountriesWithSEA(queryParams?.filter?.countryOfOrigin);
        countryOfOrigin = newCountries.map((item: string) => getCountryValue(countries, item) || item);
      }

      form.setFieldsValue({
        territories: replaceWithSEA(countryCodes ?? []),
        rightIds,
        endDate: contractEndDate,
        countryOfOrigin: replaceWithSEA(countryOfOrigin ?? []),
        title: queryParams?.filter?.title,
      });
    }
  }, [queryParams?.filter]);

  useEffect(() => {
    if (rights?.data) {
      const newData = convertToRightTreeList(rights?.data);
      setTreeList(newData);
    }
  }, [rights?.data]);

  return (
    <div className="salesheets-filter-form-container filter-form-container expand-filter">
      <Form form={form} requiredMark={false} layout="vertical" colon={false} scrollToFirstError>
        <div className="form-container">
          <div className="form-content">
            <Form.Item name="title" label="Movie Title (English)">
              <Input placeholder="Movie Title (English)" onChange={onTitleChange} />
            </Form.Item>
            <Form.Item name="territories" label="Territory">
              <Select
                placeholder="Territores"
                showSearch
                optionFilterProp="label"
                options={countries}
                onChange={onTerritoryChange}
                mode="multiple"
                allowClear
              />
            </Form.Item>
            <Form.Item name="countryOfOrigin" label="Country of Origin">
              <Select
                placeholder="Country of Origin"
                showSearch
                optionFilterProp="label"
                options={countries}
                onChange={onCountryChange}
                mode="multiple"
                allowClear
              />
            </Form.Item>
            <Form.Item label="Rights" name="rightIds">
              <TreeSelect
                showSearch
                treeNodeFilterProp="title"
                placeholder="Rights"
                allowClear
                treeDefaultExpandAll
                onChange={onTerritoryRightChange}
                treeData={treeList}
                loading={isRightLoading}
                multiple={true}
              />
            </Form.Item>
            <Form.Item name="endDate" label="Contract End Date">
              <DatePicker className="ant" format={DATE_FORMAT_FRONTEND} onChange={onContractEndChange} />
            </Form.Item>
          </div>
          <div className="form-footer">
            <SecondaryButton label={'Clear'} onClick={handleResetForm} icon={<ClearAllIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SalesheetFilterForm;
